/* PRINTING CSS  */
@media print {
  body * {
    visibility: hidden;
    width: 100%;
    print-color-adjust: exact;
  }

  .link-color {
    color: #1078b2;
  }

  #hide-on-print {
    display: none;
  }

  #rating-section-print,
  #profile-section-print {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0%;
    width: 1000px !important;
  }

  #consent-section-print {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0%;
    padding-inline: 10%;
    width: 1000px !important;
  }

  #profile-section-print,
  #profile-section-print *,
  #rating-section-print,
  #rating-section-print *,
  #consent-section-print,
  #consent-section-print * {
    visibility: visible;
  }

  /* margin of print */
  @page {
    size: auto;
    margin: 0;
    width: 100%;
  }

  /* Custom CSS for Print */
  #rating-header {
    position: relative;
  }
}

/* PRINTING CSS ---  END */

/* CSS for the whole page */

.fix-width {
  width: 250px;
}
.pagebreak {
  page-break-before: always;
}
.link-color {
  color: #1078b2;
}

/* CSS for the whole page ---  END */

.scorebar-wrapper {
  width: 100%;
  height: 100%;
}
.scorebar-wrapper {
  position: relative;
  background: #e7f0f7;
}
.scorebar-progressbar {
  position: relative;
  margin: auto;
  height: 20px;
}
.scorebar-progressbar::before,
.scorebar-progressbar::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-image: linear-gradient(
    90deg,
    #f74f4f 10%,
    #f8760c 20%,
    #ff7700 30%,
    #ffde21 65%,
    #13fa7b 75%,
    #00d35f 90%,
    #00c20d 100%
  );
  background-position: 0 0;
}
/* .scorebar-progressbar::after {
  z-index: 9;
  top: 6px;
  opacity: 0.7;
} */
.scorebar-stylization {
  position: absolute;
  z-index: 9;
  height: 6px;
  width: 95%;
  left: 2%;
  top: 2px;
  opacity: 0.3;
}
.scorebar-stylization::before,
.scorebar-stylization::after {
  content: "";
  display: block;
  position: absolute;
  height: 6px;
  top: 0;
  border-radius: 2px;
}
.scorebar-stylization::before {
  background: #fff;
  left: 0;
  right: 10px;
}
.scorebar-stylization::after {
  width: 6px;
  background: #fff;
  right: 0;
}

.file-upload .drop {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  border: 2px dashed #ccc;
  border-radius: 6px;
  text-align: center;
  padding: 30px;
}

.file-upload .drop .info {
  color: #999;
  font-size: 15px;
  line-height: 26px;
  font-weight: bold;
}
